import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { colors } from "../styles/globalStyles";
import TwitterIcon from "@mui/icons-material/Twitter";
import EMailIcon from "@mui/icons-material/EMail";
import LinkedInButton from "@mui/icons-material/LinkedIn";
import RSSIcon from "@mui/icons-material/RssFeed";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

const SocialMediaComponent = (props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            contact {
              twitter
              email
              linkedin
              rss
            }
          }
        }
      }
    `
  );

  return (
    <SocialMediaContainer>
      <SocialMediaIconButton
        target="_blank"
        rel="noopener noreferrer"
        href={site.siteMetadata.contact.twitter}
        aria-label="Follow me on Twitter"
      >
        <TwitterIcon sx={{ width: "32px", height: "32px", fill: "#55acee" }} />
      </SocialMediaIconButton>
      <SocialMediaIconButton
        target="_blank"
        rel="noopener noreferrer"
        href={site.siteMetadata.contact.linkedin}
        aria-label="Connect on LinkedIn"
      >
        <LinkedInButton
          sx={{ width: "32px", height: "32px", fill: "#0077B5" }}
        />
      </SocialMediaIconButton>
      <SocialMediaIconButton
        target="_blank"
        rel="noopener noreferrer"
        href={site.siteMetadata.contact.rss}
        aria-label="Subscribe to RSS feed"
      >
        <RSSIcon sx={{ width: "32px", height: "32px", fill: "#656666" }} />
      </SocialMediaIconButton>
      <SocialMediaIconButton
        target="_blank"
        rel="noopener noreferrer"
        href={"mailto:" + site.siteMetadata.contact.email}
        aria-label="Contact me per email"
      >
        <AlternateEmailIcon
          sx={{ width: "32px", height: "32px", fill: "#725752" }}
        />
      </SocialMediaIconButton>
      <SocialMediaIconButton
        target="_blank"
        rel="noopener noreferrer"
        href="/newsletter/"
        aria-label="Newsletter"
      >
        <EMailIcon
          sx={{ width: "32px", height: "32px", fill: colors.primaryColor }}
        />
      </SocialMediaIconButton>
    </SocialMediaContainer>
  );
};

export default SocialMediaComponent;

const SocialMediaContainer = styled("div")(({ theme }) => ({
  display: "flex",
}));

const SocialMediaIconButton = styled(IconButton)(({ theme }) => ({
  width: "42px",
  height: "42px",
}));
