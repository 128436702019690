import React from "react";
import { Link as InternalLink, useStaticQuery, graphql } from "gatsby";
import { Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { GatsbyImage } from "gatsby-plugin-image";

const AuthorComponent = (props) => {
  const { isLongDescription = false } = props;

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "img/about/me.png" }) {
        childImageSharp {
          big: gatsbyImageData(width: 120)
          small: gatsbyImageData(width: 60)
        }
      }
    }
  `);

  let descriptionsContainer;
  if (!isLongDescription) {
    descriptionsContainer = (
      <AuthorSubtitleLabel>iOS dev & creator of this site</AuthorSubtitleLabel>
    );
  } else {
    descriptionsContainer = (
      <div>
        <AuthorSubtitleLabel>
          • Author and creator of this site
        </AuthorSubtitleLabel>
        <AuthorSubtitleLabel>
          • Senior freelance iOS developer
        </AuthorSubtitleLabel>
        <AuthorSubtitleLabel>• Located in Leipzig, Germany</AuthorSubtitleLabel>
      </div>
    );
  }

  let image = isLongDescription
    ? data.file.childImageSharp.big
    : data.file.childImageSharp.small;

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <AuthorImage>
        <InternalLink to="/about/">
          <GatsbyImage image={image} alt="Image of Natascha Fadeeva" />
        </InternalLink>
      </AuthorImage>

      <div>
        <InternalLink to="/about/" style={{ textDecoration: "none" }}>
          <AuthorTitleLabel>Natascha Fadeeva</AuthorTitleLabel>
          {descriptionsContainer}
        </InternalLink>
      </div>
    </Box>
  );
};

export default AuthorComponent;

const AuthorTitleLabel = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  fontWeight: "550",
  color: theme.palette.text.primary,
}));

const AuthorSubtitleLabel = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: theme.palette.text.primary,
}));

const AuthorImage = styled("div")(({ theme }) => ({
  marginRight: "8px",
}));
