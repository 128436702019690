import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { colors } from "../styles/globalStyles";
import { styled } from "@mui/material/styles";

/*
Either use directly in mdx files with:
<affiliateView type="sponsor"></affiliateView>
*/

const AffiliateView = ({ type, children }, props) => {
  const data = useStaticQuery(graphql`
    query {
      iosQuestionsCover: file(
        relativePath: { eq: "img/affiliate/ios-questions-cover.jpg" }
      ) {
        childImageSharp {
          logo: gatsbyImageData(width: 100)
        }
      }
      books: file(relativePath: { eq: "img/affiliate/books.png" }) {
        childImageSharp {
          logo: gatsbyImageData(width: 40)
        }
      }
      sponsorEssentialDeveloper: file(
        relativePath: { eq: "img/affiliate/logo-essential-developer.png" }
      ) {
        childImageSharp {
          logo: gatsbyImageData(width: 60)
        }
      }
      sponsorProxyman: file(
        relativePath: { eq: "img/affiliate/logo-proxyman.png" }
      ) {
        childImageSharp {
          logo: gatsbyImageData(width: 90)
        }
      }
      sponsorGlassfy: file(
        relativePath: { eq: "img/affiliate/logo-glassfy.png" }
      ) {
        childImageSharp {
          logo: gatsbyImageData(width: 60)
        }
      }

      sponsorFrankThamel: file(
        relativePath: { eq: "img/affiliate/logo-frankthamel.png" }
      ) {
        childImageSharp {
          logo: gatsbyImageData(width: 60)
        }
      }

      sponsorCodemagic: file(
        relativePath: { eq: "img/affiliate/logo-codemagic.png" }
      ) {
        childImageSharp {
          logo: gatsbyImageData(width: 100)
        }
      }
    }
  `);

  /*
  sponsor = For pages that are not articles
  sponsor1 = First half in long articles
  sponsor2 = End of long articles
  sponsor3 = First half in short articles
  sponsor4 = End of short articles
   */

  if (type == null || type === "") {
    return <div></div>;
  }

  let title = "";
  let text = "";
  let affiliateLink = "";
  let buttonTitle = "CLICK TO LEARN MORE";
  let image;

  switch (type) {
    case "sponsor":
    case "sponsor1":
    case "sponsor2":
    case "sponsor3":
    case "sponsor4":
      title = "Debug 10x faster with Proxyman";
      text =
        "Your ultimate tool to capture HTTPs requests/ responses, natively built for iPhone and macOS. You’d be surprised how much you can learn about any system by watching what it does over the network.";
      affiliateLink = "https://proxyman.io/";
      image = data.sponsorProxyman.childImageSharp.logo;
      buttonTitle = "Get started for free";
      break;
    case "sponsor-glassfy":
      title = "Build, manage, and grow in app subscriptions with Glassfy";
      text =
        "Glassfy provides an open source SDK & backend to unlock your app’s potential. You deserve to focus on your app, and stop wasting time on subscription infrastructure and maintenance.";
      affiliateLink =
        "https://www.glassfy.io?utm_source=partn&utm_medium=web&utm_campaign=tanaschita";
      image = data.sponsorGlassfy.childImageSharp.logo;
      break;
    case "sponsor-codemagic":
      title = "Ship your iOS apps faster!";
      text =
        "CI/CD for iOS and macOS developers. Fast builds on M1 machines and predictable pricing. Automatic code signing and publishing with really good documentation.";
      affiliateLink =
        "https://codemagic.io/ios-continuous-integration?utm_source=tanaschita&utm_medium=tanaschita&utm_campaign=ios_sponsorship/";
      image = data.sponsorCodemagic.childImageSharp.logo;
      buttonTitle = "Start building now";
      break;
    case "sponsor-essential-developer":
      title = "Join the FREE iOS Architect Crash Course (for a limited time!)";
      text =
        "If you’re a mid/senior iOS developer who’s looking to improve both your skills and salary level, then join this 100% free online crash course. Hurry up because it's available only until September 25th!";
      affiliateLink =
        "https://iosacademy.essentialdeveloper.com/p/ios-architect-crash-course-tans2m54/";
      image = data.sponsorEssentialDeveloper.childImageSharp.logo;
      break;
    case "sponsor-proxyman":
      title = "Debug 10x faster with Proxyman";
      text =
        "Your ultimate tool to capture HTTPs requests/ responses, natively built for iPhone and macOS. You’d be surprised how much you can learn about any system by watching what it does over the network.";
      affiliateLink = "https://proxyman.io/";
      image = data.sponsorProxyman.childImageSharp.logo;
      buttonTitle = "Get started for free";
      break;
    case "sponsor-tanaschita-ebook-ios-qa":
      title =
        "Preparing for a technical iOS job interview - updated for iOS 18";
      text =
        "Check out my book on preparing for a technical iOS job interview with over 200 questions & answers. Test your knowledge on iOS topics such as Swift, SwiftUI, Combine, HTTP Networking, Authentication, SwiftData & Core Data, Concurrency with async/await, Security, Automated Testing, Machine Learning and more.";
      affiliateLink = "https://tanaschita.com/books/";
      image = data.iosQuestionsCover.childImageSharp.logo;
      buttonTitle = "LEARN MORE";
      break;
    case "sponsor-frank-thamel":
      title = "Scalable & Accessible Mobile Design Systems";
      text =
        "Unlock the full potential of SwiftUI! Build robust, maintainable, and scalable apps that solidify your brand while ensuring a seamless, accessible user experience for everyone.";
      affiliateLink = "https://iosdevlibrary.lemonsqueezy.com";
      image = data.sponsorFrankThamel.childImageSharp.logo;
      buttonTitle = "LEARN MORE";
      break;
    case "":
      title = "News from tanaschita.com: I'm now offering sponsorship";
      text =
        "By publishing a new article on different iOS topics every week, tanaschita.com is constantly growing in the developer community and may provide a great audience for you as a sponsor.";
      affiliateLink = "https://tanaschita.com/sponsorship/";
      image = data.books.childImageSharp.logo;
      break;
    default:
      console.error("Not found affiliate type " + type);
      return <div></div>;
  }
  return (
    <a
      href={affiliateLink}
      rel="nofollow noreferrer"
      target="_blank"
      style={{ textDecoration: "none" }}
    >
      <SponsorshipContainer>
        <SponsorshipImageContainer>
          <GatsbyImage image={image} alt="Sponsorship logo" />
        </SponsorshipImageContainer>
        <SponsorshipTextsContainer>
          <SponsorshipTitleContainer>{title}</SponsorshipTitleContainer>
          <SponsorshipTextContainer>{text}</SponsorshipTextContainer>
          <SponsorshipButtonContainer>{buttonTitle}</SponsorshipButtonContainer>
        </SponsorshipTextsContainer>
      </SponsorshipContainer>
    </a>
  );
};

export default AffiliateView;

const SponsorshipContainer = styled("div")(({ theme }) => ({
  background: theme.palette.adBackground,
  padding: "10px 10px 8px 12px",
  marginTop: "30px",
  borderRadius: "8px",
  border: "2px solid #876349",
  display: "flex",

  [theme.breakpoints.down("xs")]: {
    padding: "8px",
  },
  "&::before": {
    content: '"Sponsor"',
    background: "#876349",
    color: "#fff",
    borderRadius: "6px",
    padding: "1px 6px",
    position: "absolute",
    marginTop: "-20px",
    fontSize: "10px",
    textTransform: "uppercase",
  },
}));

const SponsorshipTextsContainer = styled("div")(({ theme }) => ({
  flexFlow: "column wrap",
  flexShrink: "5",
  wordWrap: "break-word",
  alignSelf: "flex-start",
  padding: "0px",
  margin: "0px",
  marginTop: "5px",
  marginLeft: "12px",
}));

const SponsorshipTitleContainer = styled("div")(({ theme }) => ({
  fontSize: "18px",
  fontWeight: "bold",
  marginBottom: "5px",
  color: theme.palette.text.primary,
}));

const SponsorshipTextContainer = styled("div")(({ theme }) => ({
  fontSize: "16px",
  color: theme.palette.text.primary,
}));

const SponsorshipImageContainer = styled("div")(({ theme }) => ({
  margin: "10px",
  flexShrink: "0",
  alignSelf: "center",
}));

const SponsorshipButtonContainer = styled("div")(({ theme }) => ({
  fontSize: "16px",
  color: colors.primaryColor,
  fontWeight: "600",
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "10px",
  marginRight: "10px",
}));
